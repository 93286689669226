<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-link class="brand-logo">
          <img
            :src="appLogoImage"
            style="object-fit: cover; width: 120px; height: 120px"
          />
          <img :src="appName" style="object-fit: cover; width: 120px; height: 120px" />
        </b-link>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            สร้างบัญชีใหม่
          </b-card-title>
         

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- username -->
              <b-form-group label="ชื่อผู้ใช้งาน" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="อีเมล" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="รหัสผ่าน">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group label-for="register-password" label="ยืนยันรหัสผ่าน">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password2"
                      v-model="password2"
                      class="form-control-merge"
                      :type="passwordFieldType2"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordcon2"
                        class="cursor-pointer"
                        @click="setpass()"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  ฉันยอมรับนโยบาย
                  <a href="https://socio.co.th/privacy-statement" target="_blank"
                    >ความเป็นส่วนตัวและข้อกำหนด</a
                  >
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                สร้างบัญชี
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>คุณมีบัญชีอยู่แล้ว?</span>
            <b-link :to="{ name: 'auth-login-v2' }">
              <span>&nbsp;ล็อคอิน</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">หรือ</div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button type="submit" variant="primary" block size="lg" @click="login">
              ลงชื่อเข้าใช้งานด้วย LINE
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import api from "@/api";
import { $themeConfig } from "@themeConfig";
export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      username: "",
      userEmail: "",
      password: "",
      password2: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      user_main: 0,
      passwordFieldType2:'text',
      passwordcon2:'EyeIcon'
    };
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      // App Name
      appName,
      appLogoImage,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    setpass(){
     console.log( );
     if(document.getElementById ("password2").type == 'text'){

       this.passwordcon2 = "EyeIcon" ;
      }else{
       this.passwordcon2 =  "EyeOffIcon";

     }
 
    },
    login() {
      var url =
        "https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657484988&redirect_uri=https://manager.socio.co.th/auth&state=dev12345&scope=profile%20openid%20email&nonce=09876xyz";
      window.location.href = url;
    },
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          // if (this.password !== this.password2) {
          //   this.$swal({
          //     title: "รหัสผ่านไม่ตรงกัน!",
          //     icon: "error",
          //     customClass: {
          //       confirmButton: "btn btn-primary",
          //     },
          //     buttonsStyling: false,
          //   });
          // } else {
            const params = {
              status: this.status,
              name: this.username,
              email: this.userEmail,
              password: this.password,
              user_main: this.user_main,
            };

            this.$swal({
              title: "คุณต้องการสมัครสมาชิก ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                api
                  .post("/register", params)
                  .then((response) => {
                    console.log("response.data :>> ", response.data);
                    this.$swal({
                      icon: "success",
                      title: "สำเร็จ! ล็อกอินเพื่อเข้าสู่ระบบ",

                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    });
                    this.$router.push("/login");
                  })
                  .catch((err) => {
                    this.$swal({
                      title: "เช็กข้อมูลของท่านอีกครั้ง!",
                      icon: "error",
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                  });
              } else if (result.dismiss === "cancel") {
                this.$swal({
                  title: "Cancelled",
                  text: "Your imaginary file is safe :)",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          // }
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
